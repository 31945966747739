export default {
    fields: {
        id: {type:'primary'},
        title:{title:'标题'},
        pid:{title:'分类',type:'dict_key',dict_key:'offline_type'},
        name:{title:'URL名称'},
        image:{title:'静态图片',type:'single_file',limit:1,accept:'.png,.jpg'},
        gif:{title:'动态图片',type:'single_file',limit:1,accept: '.gif,.mp4'},
        keyword: {title: '网页关键字'},
        description: {title: '网页描述'},
        code: {title: '代码',type:'code'},
        status:{title:'是否启用',type:'dict_tag',dict_key: 'is_enabled'},
        attach_file:{title:'附件',type:'single_file',limit:1,accept: '.zip,.rar'},
        creator_id: {title: '创建人'},
        create_time: {title: '创建时间',type:'date',format:'Y-m-d',sortable:'custom'},
    },
    rules: {
        pid: [
            {required: true, message: '请指定分类', trigger: 'change'},
        ],
        title: [
            {required: true, message: '请指定标题', trigger: 'blur'},
        ],
        name: [
            {required: true, message: '请指定名称', trigger: 'blur'},
        ],
        keyword: [
            {required: true, message: '请指定网页关键字', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['title','pid','name','status','create_time'],
        },
        add:{
            show:true,
            title:'添加示例',
            fields:['title','pid','name','keyword','description','status','image','gif','attach_file','code'],
        },
        edit:{
            show:true,
            title:'编辑示例',
            fields:['id','title','pid','name','keyword','description','status','image','gif','attach_file','code'],
        },
        search: {
            fields: ['title','pid','status'],
        },
    }
}
