export default {
    fields: {
        answer_id: {type: 'primary'},
        username: {title:'创建人'},
        title: {title: '标题'},
        content: {
            title: '内容', type: 'bbs_editor', config: {
                height: 290,
                url:'/bbs/upload/image',
                placeholder:'请输入内容',
                menus: [
                    'bold',
                    'fontSize',
                    'foreColor',
                    'backColor',
                    'link',
                    'image',
                ]
            }
        },
        create_time:{title:'创建时间',type:'date',format:'Y-m-d H:i:s',width:180},
        type_id: {title: '问题分类', type: 'dict_select', dict_key: 'answer_type'},
        ios_id: {title: 'IOS_ID'},
        android_id: {title: 'Android_ID'},
        review_status:{type: 'dict_select', dict_key: 'review_status',title:'审核情况'},
        app_qq_join_key: {title: 'Key'},
        else_config: {title: '其它配置', type: 'textarea'},
        ad_substitute: {title: '是否补充', type: 'dict_select', dict_key: 'is_yes_or_no', width: 80},
        enable: {title: '是否启用', type: 'dict_tag', dict_key: 'is_enabled', width: 100},
        priority: {title: '是否优先', type: 'dict_select', dict_key: 'is_yes_or_no', width: 80},
    },
    rules: {
        title: [
            {required: true, message: '请指定问题标题', trigger: 'blur'},
        ],
        type_id: [
            {required: true, message: '请指定问题分类', trigger: 'blur'},
        ],
    },
    group: {
        show: {
            fields: ['name', 'times', 'ad_id', 'ios_id', 'android_id', 'enable', 'priority', 'ad_substitute'],
        },
        verify: {
            fields: ['title', 'create_time'],
        },
        editQq: {
            fields: ['app_qq_join_key'],
            show: true,
            title: '修改QQ加群Key'
        },
        editElseConfig: {
            fields: ['else_config'],
            show: true,
            title: '广告配置JSON'
        },
        edit: {
            fields: ['id', 'name', 'ad_id', 'times', 'enable', 'priority', 'ad_substitute'],
            show: true,
            title: '修改广告配置'
        },
        editOld: {
            fields: ['id', 'name', 'times', 'ad_id', 'ios_id', 'android_id', 'enable', 'priority', 'ad_substitute'],
            show: true,
            title: '修改广告配置'
        },
        add: {
            fields: ['title', 'type_id', 'content'],
            show: true,
            title: '添加问答'
        },
        editAnswer: {
            fields: ['answer_id','title', 'content'],
            show: true,
            title: '修改问答'
        },
        search: {
            fields: ['review_status'],
        }
    }
}
