export default {
    fields: {
        id:{type:'primary'},
        url: {title: '订阅地址'},
        title: {title: '名称',width:120,fixed:true},
        buy_url:{title:'购买URL',minWidth:300},
        limit:{title:'限制数',width:70},
        in_use:{title:'使用数',width:70},
        enable:{title:'是否启用',width:80,type:'dict_tag',dict_key:'is_enabled'},
        _all_:{title:'总代理数',width:80},
        _unique_:{title:'独立IP',width:70},
        _forbidden_:{title:'被封',width:70},
        _never_:{title:'从未使用',width:80},
        type:{title:'使用类型',width:100,type:'dict_select',dict_key:'subscribe_type',options:[{label:'仅历史影像',key:'1'},{label:'仅内网下载',key:'2'},{label:'通用',key:'3'}]},
        deadline:{title:'过期时间',type:'date',valueFormat:'timestamp',width:120,format:'Y-m-d'},
        left:{title:'剩余流量',width:100},
        tip:{title:'备注'},
        resubscribe:{title:'重新订阅',type:'switch'},
        resubscribe_time:{title:'下次订阅时间',type:'date',width:120,format:'Y-m-d'},
        left_time:{title:'流量检查时间',width:170,format:'Y-m-d'},
    },
    rules: {
        title: [
            {required: true, message: '标题不可以为空', trigger: 'blur'},
        ],
        url: [
            {required: true, message: '订阅地址不可以为空', trigger: 'blur'},
        ],
        buy_url: [
            {required: true, message: '购买地址不可以为空', trigger: 'blur'},
        ],
        limit: [
            {required: true, message: '请指定同时使用数', trigger: 'blur'},
        ],
        deadline: [
            {required: true, message: '请指定订阅有效期', trigger: 'blur'},
        ],
        type: [
            {required: true, message: '指定订阅的使用类型', trigger: 'blur'},
        ],
    },
    group: {
        show:{
            fields:['title','buy_url','enable','limit','in_use','_all_','_unique_','_forbidden_','_never_','deadline','type','left','resubscribe_time','left_time','tip'],
        },
        add:{
            fields:['title','url','buy_url','limit','tip','type','deadline'],
            title: '创建订阅',
            data: {},
            show: true,
        },
        edit:{
            fields:['id','title','url','buy_url','limit','tip','type','deadline','enable','resubscribe'],
            title: '编辑订阅',
            data: {},
            show: true,
        },
        search: {
            fields: ['title'],
        }
    }
}