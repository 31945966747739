export default {
    fields: {
        reply_id: {type: 'primary'},
        content: {
            title: '内容', type: 'bbs_editor', config: {
                height: 290,
                url:'/bbs/upload/image',
                placeholder:'请输入内容',
                menus: [
                    'bold',
                    'fontSize',
                    'foreColor',
                    'backColor',
                    'link',
                    'image',
                ]
            }
        },
    },
    rules: {
        content: [
            {required: true, message: '请输入内容', trigger: 'blur'},
        ],
    },
    group: {
        editReply: {
            fields: ['reply_id', 'content'],
            show: true,
            title: '修改问答'
        },
    }
}
