export default {
    fields: {
        group_id:{type:'primary'},
        group_title: {title: '用户组',width:150},
        create_time: {title: '创建时间',type:'daterange'},
    },
    rules: {
        group_title: [
            {required: true, message: '组名不可以为空', trigger: 'blur'},
        ],
    },
    group: {
        show:{
            fields:['group_title','create_time'],
        },
        search: {
            fields: ['group_title'],
        },
        edit_group: {
            fields: ['group_id','group_title'],
            title: '修改用户组',
            data: {},
            show: true,
        },
        add_group: {
            fields: ['group_title'],
            title: '新增用户组',
            data: {},
            show: true,
        },
    }
}