export default {
    fields: {
        product_id: {type: 'primary',title:'产品ID'},
        title: {title: '产品名称'},
        platform: {title: '平台', width: 100, type: 'dict_key', dict_key: 'platform_list_product'},
        ios_key: {title: 'IOS产品ID'},
        password: {title: '密码', width: 60,type:'dict_key',dict_key:'is_yes_or_no'},
        is_recommended: {title: '推荐', width: 60,type:'dict_key',dict_key:'is_yes_or_no'},
        price: {title: '付款金额', width: 60},
        show_price: {title: '原价', width: 90},
        time_interval: {title: '时间周期', width: 90},
        info: {title: '说明'},
        status: {title: '是否启用', width: 90, dict_key: 'is_enabled', type: 'dict_tag'},
        create_time: {title: '创建时间', type: 'daterange',format:'Y-m-d', sortable: 'custom', valueFormat: 'timestamp'},
    },
    rules: {
        title: [
            {required: true, message: '请指定名称', trigger: 'blur'},
        ],
        platform: [
            {required: true, message: '请指定平台', trigger: 'blur'},
        ],
        price: [
            {required: true, message: '请指定价格', trigger: 'blur'},
        ],
        show_price: [
            {required: true, message: '请指定原价', trigger: 'blur'},
        ],
        time_interval: [
            {required: true, message: '请指定时间周期', trigger: 'blur'},
        ],
    },
    group: {
        show: {
            fields: ['product_id', 'title','is_recommended', 'platform', 'price', 'show_price', 'ios_key', 'password', 'status', 'info', 'create_time'],
        },
        add: {
            title:'添加产品',
            show:true,
            fields: ['title', 'platform', 'price', 'show_price','is_recommended','time_interval', 'ios_key', 'password', 'status', 'info'],
        },
        edit: {
            title:'编辑产品',
            show:true,
            fields: ['product_id', 'title','platform','is_recommended', 'price', 'show_price','time_interval', 'ios_key', 'password', 'status', 'info'],
        },
        search: {
            fields: ['title', 'platform'],
        },
    }
}
