export default {
    fields: {
        refund_id: {type: 'primary'},
        order_id: {type: 'hidden'},
        phone: {title: "电话", disabled: true},
        price: {title: '付款金额',  disabled: true,},
        refund_price: {title: '退款金额'},
        status: {title: '退款状态', dict_key: 'is_refunded_yes_or_no', type: 'dict_tag'},
        done_time: {title: '完成时间', type: 'daterange', valueFormat: 'timestamp'},
        pay_time: {title: '付款时间', type: 'daterange', valueFormat: 'timestamp'},
        creator_id: {title: '提交人'},
        done_user_id: {title: '操作人'},
        info: {title: '退款说明', type: 'textarea'},
        create_time: {title: '申请时间', type: 'daterange', sortable: 'custom', valueFormat: 'timestamp'},
    },
    rules: {
        refund_price: [
            {required: true, message: '请指定退款金额', trigger: 'blur'},
        ]
    },
    group: {
        show: {
            fields: ['price', 'phone', 'refund_price', 'status', 'done_time', 'create_time', 'info'],
        },
        //确认退款数据
        confirm_refund:{
            show:true,
            title:'确认退款',
            fields:['refund_id','price','phone','refund_price'],
        },
        add_refund: {
            show: true,
            title: '添加退款记录',
            fields: ['order_id','phone', 'price', 'refund_price', 'info'],
        },
        search: {
            fields: ['status'],
        },
    }
}
