export default {
    fields: {
        username: {title: '用户名',width:120},
        nickname: {title: '用户称呼',width:120},
        phone: {title: '电话',width:120},
        content:{title:'内容'},
        version:{title:'版本号',width:90},
        replied:{title:'回复情况',type:'select',options:[{key: '0', label: '未回复'}, {key: '1', label: '已回复'},{key: '100', label: '不回复'}]},
        platform:{title:'平台',type:'select',options:[{key: 'android', label: '安卓'}, {key: 'ios', label: 'IOS'}]},
        create_time: {title: '创建时间',type:'daterange',width:160,sortable:'custom',valueFormat:'timestamp'},
    },
    rules: {
        phone: [
            {required: true, message: '电话号码不可以为空', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['username','nickname','phone','content','version'],
        },
        search: {
            fields: ['phone','platform','replied','version','create_time'],
        },
    }
}