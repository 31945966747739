export default {
    fields: {
        ip:{type:'primary'},
        gg18_: {title: '服务器ID'},
        gg22_: {title: '每秒允许请求'},
        gg34_: {title: '购买者'},
        gg38_: {title: '区域'},
        gg27_:{title:'代理地址'},
        gg54_:{title:'上级IP',placeholder:'IP:端口号'},
        gg31_:{title:'启用代理',type:'switch'},
        gg39_:{title:'代理订阅地址'},
        gg40_:{title:'重新订阅',type:'switch'},
        gg44_:{title:'POST1放行比例'},
        gg41_: {title: '代理服务器', type: 'cascader', options: []},
        gg33_:{title:'过期时间',type:'date',valueFormat:'timestamp'},
        gg57_:{title:'服务端口'},
        gg55_:{title:'代理端口'},
        __all__:{title:'请设置值'},
        __type__:{type:'hidden'},
    },
    rules: {
        gg18_: [
            // {required: true, message: '服务器ID不可以为空', trigger: 'blur'},
        ],
    },
    group: {
        editConfig: {
            fields: ['ip','gg18_','gg57_','gg54_','gg34_','gg38_','gg44_','gg33_','gg22_'],
            title: '修改服务器配置信息',
            data: {},
            show: true,
        },
        createServer: {
            fields: ['ip','gg57_','gg55_'],
            title: '创建服务器',
            data: {},
            show: true,
        },
        editAll: {
            fields: ['__all__','__type__'],
            title: '修改全部的值',
            data: {},
            show: true,
        },
        editSubscribe: {
            fields: ['ip','gg39_','gg40_'],
            title: '修改服务器订阅',
            data: {},
            show: true,
        },
        editProxy: {
            fields: ['ip','gg27_','gg31_'],
            title: '修改服务器代理',
            data: {},
            show: true,
        },
        selectProxy: {
            fields: ['ip','gg41_','gg31_','gg57_'],
            title: '修改服务器代理',
            data: {},
            show: true,
        },
    }
}