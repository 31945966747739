export  default {
    fields: {
        id:{type:'primary'},
        title: {title: '标题'},
        name: {title: '名称'},
    },
    rules: {

    },
    group: {
        add_menu: {
            fields: ['name', 'title'],
            title: '添加导航菜单',
            data: {type: 'menu', state: true},
            show: true,
        },
        edit_menu: {
            fields: ['id','title'],
            title: '编辑导航菜单',
            show: true,
        }
    }
}
