export default {
    fields: {
        user_id:{type:'primary'},
        username: {title: '用户名',width:150},
        staff_name: {title: '员工名',width:150},
        password: {title: '密码',width:150},
        group_id:{title:'用户组',type:'checkbox',dict_key:'user_group'},
    },
    rules: {
        username: [
            {required: true, message: '用户名不可以为空', trigger: 'blur'},
        ],
        staff_name: [
            {required: true, message: '员工名不可以为空', trigger: 'blur'},
        ],
        group_id: [
            {required: true, message: '请指定用户组', trigger: 'change'},
        ],
    },
    group: {
        show:{
            fields:['username','staff_name','group_id'],
        },
        search: {
            fields: ['username','staff_name',],
        },
        edit_user: {
            fields: ['user_id','username','staff_name','group_id','password'],
            title: '修改用户',
            data: {},
            show: true,
        },
        add_user: {
            fields: ['username','staff_name','group_id','password'],
            title: '新增用户',
            data: {},
            show: true,
        },
    }
}
