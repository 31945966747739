export  default {
    fields: {
        username: {title: '用户名',width:240,type:'primary'},
        connections: {title: '连接数',width:100},
        service: {title: '使用服务',},
        ips:{title:'登陆IP'}
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        add_mail: {
            fields: ['username'],
            title: '添加用户',
            data: {

            },
            show: true,
        },
        show:{
            fields:['username','connections','service','ips'],
        },
        edit: {
            title:'编辑用户信息',
            fields: ['username','active'],
        },
        search: {
            fields: ['username','ips']
        },
    }
}