export default {
    fields: {
        id:{type:'primary'},
        name: {title: '广告标志',width:110},
        times: {title: '展示次数',width:160},
        ad_id:{title:'广告ID'},
        type:{title:'广告ID',type:'hidden'},
        ios_id:{title:'IOS_ID'},
        android_id:{title:'Android_ID'},
        app_qq_join_key:{title:'Key'},
        else_config:{title:'其它配置',type:'textarea'},
        ad_substitute:{title:'是否补充',type:'dict_select',dict_key:'is_yes_or_no',width:80},
        enable:{title:'是否启用',type:'dict_tag',dict_key:'is_enabled',width:100},
        priority:{title:'是否优先',type:'dict_select',dict_key:'is_yes_or_no',width:80},
    },
    rules: {
        times: [
            {required: true, message: '请指定展示次数', trigger: 'blur'},
        ],
        ad_id: [
            {required: true, message: '请指定广告ID', trigger: 'blur'},
        ],
        enable: [
            {required: true, message: '请指定是否启用', trigger: 'blur'},
        ],
        priority: [
            {required: true, message: '请指定是否优先', trigger: 'blur'},
        ],
        ad_substitute: [
            {required: true, message: '请指定是否补充', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['name','times','ad_id','ios_id','android_id','enable','priority','ad_substitute'],
        },
        editQq:{
            fields:['app_qq_join_key'],
            show:true,
            title:'修改QQ加群Key'
        },
        editElseConfig:{
            fields:['else_config'],
            show:true,
            title:'广告配置JSON'
        },
        edit:{
            fields:['id','name','ad_id','times','enable','priority','ad_substitute'],
            show:true,
            title:'修改广告配置'
        },
        editOld:{
            fields:['id','name','times','ad_id','ios_id','android_id','enable','priority','ad_substitute'],
            show:true,
            title:'修改广告配置'
        },
        add:{
            fields:['name','ad_id','times','type','enable','priority','ad_substitute'],
            show:true,
            title:'添加广告配置'
        },
        search: {
            fields: ['name'],
        }
    }
}