export default {
    fields: {
        mobile: {title: '手机号'},
        code: {title: '短信验证码',},
        time: {title: '发送时间',type:'date'},
        sent: {title: '发送状态',type:'dict_tag',dict_key: 'is_sms_sent',width:100},
        verify:{title:'是否验证',type:'dict_tag',dict_key:'is_sms_verify',width:100},
    },
    rules: {

    },
    group: {
        show:{
            fields:['mobile','code','time','sent','verify'],
        },
        edit:{
            fields:['id','times','enable','priority'],
            show:true,
            title:'修改'
        },
        search: {
            fields: ['mobile'],
        }
    }
}