export default {
    fields:{
        filename: {title: '文件名'},
        filesize: {title: '文件大小',sortable:true},
        md5: {title: '文件MD5'},
        from: {title: '发件人'},
        platform: {title: '平台'},
        title:{title:'类型',type:'select',options:[{key:'AAAAA',label:'文件类型'}]},
        date: {title: '创建时间',sortable:true,type:'daterange',valueFormat:'timestamp'},
    },
    group:{
        show:{
            fields:['filename','filesize','from','platform','date'],
        },
        search:{
            fields:['date'],
        },
        allSearch:{
            fields:['title','date'],
        },

    }
}