export  default {
    fields: {
        id:{type:'primary'},
        server_id: {title: '服务器ID',width:110},
        ip: {title: '代理IP',width:160},
        status:{title:'状态',type:'dict_tag',dict_key:'proxy_server_status',width:120,fixed:'right'},
        pid:{title:'订阅',type:'dict_select',dict_key:'proxy_subscribe'},
        server_usage:{title:'使用情况',type:'dict_tag',dict_key:'proxy_server_usage'},
        update_time:{title:'更新时间',type:'date',format:'m-d H:i:s',width:130},
        forbidden_time:{title:'最后被封时间',type:'date',format:'m-d H:i:s',width:130},
        next_check_time:{title:'下次检查时间',type:'date',format:'m-d H:i:s',width:130},
        forbidden_server_id:{title:'被封服务器ID',search_width:'100px',width:120},
        ps:{title:'代理名称',fixed:true,minWidth:300},
    },
    rules: {

    },
    group: {
        show:{
            fields:['ps','server_id','ip','pid','update_time','forbidden_server_id','forbidden_time','next_check_time','status'],
        },
        search: {
            fields: ['server_id','pid','ps','ip','forbidden_server_id','status'],
        }
    }
}