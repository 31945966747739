export default {
    fields: {
        username: {title: '用户名',width:240,type:'primary'},
        phone: {title: '电话',width:150},
        nickname: {title: '用户昵称'},
        member_time: {title: '会员到期时间',type:'date'},
        create_time: {title: '创建时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
        active:{title:'状态',type:'tag',options:[{key:0,label:'禁用'},{key:1,label:'正常'}]}
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        add_mail: {
            fields: ['username'],
            title: '添加用户',
            data: {

            },
            show: true,
        },
        show:{
            fields:['phone','username','nickname','member_time','create_time','active'],
        },
        edit: {
            title:'编辑用户信息',
            fields: ['username','active'],
        },
        search: {
            fields: ['phone','username','nickname','create_time'],
        },
    }
}
