import app from '@/main';
import appAd from '@/fields/appAd';
import historyServerItem from '@/fields/historyServerItem';
import menu from '@/fields/menu';
import payList from "@/fields/app/payList";
import mailBox from '@/fields/mailBox';
import userReport from '@/fields/userReport';
import mailOnline from '@/fields/mailOnline';
import comment from '@/fields/comment';
import systemUser from '@/fields/systemUser';
import email from '@/fields/email';
import systemGroup from '@/fields/systemGroup';
import groupSending from '@/fields/groupSending';
import historySubscribeItem from '@/fields/historySubscribeItem';
import historyServer from '@/fields/historyServer';
import appSms from '@/fields/appSms';
import appAdPc from "@/fields/appAdPc";
import menuType from "@/fields/menuType";
import menuCn from "@/fields/menuCn";
import userClear from "@/fields/userClear";
import offlineList2d from "@/fields/com/offlineList2d";
import offlineList3d from "@/fields/com/offlineList3d";
import offlineList2dType from "@/fields/com/offlineList2dType";
import offlineList3dType from "@/fields/com/offlineList3dType";
import productList from "@/fields/app/productList";
import freeGis from "@/fields/app/freeGis";
import gisBind from "@/fields/app/gisBind";
import payListRefund from "@/fields/app/payListRefund";
import answer from "@/fields/answer";
import answerReply from "@/fields/answerReply";
import bbsUser from "@/fields/bbs/bbsUser";
let fields = {
    menuCn,
    bbsUser,
    answer,
    answerReply,
    payListRefund,
    gisBind,
    freeGis,
    productList,
    payList,
    offlineList2d,
    offlineList3d,
    offlineList2dType,
    offlineList3dType,
    userClear,
    menuType,
    appAd,
    appAdPc,
    appSms,
    historyServerItem,
    menu,
    mailBox,
    userReport,
    mailOnline,
    comment,
    systemUser,
    email,
    systemGroup,
    groupSending,
    historySubscribeItem,
    historyServer,

};

export default {
    show(table, group) {
        table = JSON.parse(JSON.stringify(fields[table]));
        group = table['group'][group];
        if (!group['fields']) group['fields'] = [];
        return group['fields'].map(v => {
            let base = {};
            if (typeof v === 'object') {
                base['name'] = v;
                base = Object.assign({}, table['fields'][v.name], v);
            } else {
                base = table['fields'][v];
                base['name'] = v;
            }
            return base;
        });
    },
    search(table, group) {
        table = JSON.parse(JSON.stringify(fields[table]));
        group = table['group'][group];
        if (!group['fields']) group['fields'] = [];
        return group['fields'].map(v => {
            return Object.assign({}, table['fields'][v], {name: v});
        });
    },
    get(table, group, data) {
        let _table = table;
        table = JSON.parse(JSON.stringify(fields[table]));
        group = table['group'][group];
        if (!group['fields']) group['fields'] = Object.keys(table['fields']);
        if (!group['rules']) group['rules'] = {};
        group.data = data ? JSON.parse(JSON.stringify(data)) : group.data ? group.data : {};
        group['fields'] = group['fields'].map(v => {
            // console.log(v);
            let base = {};
            if (typeof v === 'object') {
                //base['name']=v;
                base = Object.assign({}, table['fields'][v.name], v);
            } else {
                base = table['fields'][v];
                base['name'] = v;
            }
            table['rules'][v] ? group['rules'][v] = table['rules'][v] : true;
            return base;
        });
        group.data = this.mappingData(group.data, group.fields);
        let parseData = JSON.parse(JSON.stringify(group));
        if (parseData.rules) {
            //这里的主要原因是有可能验证器有函数，不要让函数丢掉了
            for (let k in parseData.rules) {
                fields[_table].rules[k].map((v, index) => {
                    if (v.validator && typeof v.validator === 'function') parseData.rules[k][index].validator = v.validator;
                });
            }
        }
        return parseData;
    },
    mappingData(data, fields) {
        fields.map(v => {
            switch (v.type) {
                // case 'select':
                //     if (data[v.name]) data[v.name] = data[v.name] + '';
                //     break;
                case 'switch':
                    data[v.name] = ['1', 'on', 1, true].indexOf(data[v.name]) !== -1;
                    break;
                case 'next_bill_time':
                    data[v.valueKey] = data[v.valueKey] ? data[v.valueKey] * 1000 : '';
                    break;
                case 'file':
                case 'single_file':
                    if (data[v.name]) {
                        if (typeof data[v.name] === 'string') data[v.name] = [{url: data[v.name]}];
                    } else {
                        data[v.name] = [];
                    }
                    break;
                case 'date':
                case 'month':
                    data[v.name] = data[v.name] ? data[v.name] * 1000 : '';
                    break;
                case 'image_upload'://多个图片的上传
                case 'single_image'://单个图片上传
                    if (!data[v.name]) data[v.name] = [];
                    if (typeof data[v.name] === 'string') data[v.name] = [{url: v.baseUrl ? v.baseUrl + data[v.name].replace(v.baseUrl, '') : data[v.name]}];
                    data[v.name].map(vv => {
                        let url = v.baseUrl ? v.baseUrl + vv.url.replace(v.baseUrl, '') : vv.url;
                        return Object.assign({}, vv, {url});
                    });
                    break;
            }
        });
        return data;
    },
}
