export  default {
    fields: {
        id:{type:'primary'},
        username: {title: '举报人',width:240},
        reporter: {title: '被举报人',width:150},
        info: {title: '举报内容'},
        create_time: {title: '创建时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
        platform:{title:'平台',type:'tag',options:[{key:'ios',label:'IOS'},{key:'android',label:'安卓'}]}
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['username','reporter','info','create_time','platform'],
        },
        edit: {
            title:'编辑用户信息',
            fields: ['username','active'],
        },
        search: {
            fields: ['username','reporter'],
        },
    }
}