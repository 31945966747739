import {checkPhone} from "@/utils/tools";
export default {
    fields: {
        order_id: {type:'primary'},
        title: {title: '查询',placeholder:'支持电话、加密狗、序列号',type:'long_search'},
        bind: {title: '绑定情况',width:150,type:'dict_tag',dict_key:'bind_status'},
        product_type:{title:'产品类型',type:'dict_key',dict_key:'product_type'},
        username: {title: '用户名',},
        nickname:{title:'用户昵称'},
        hardware_no:{title:'硬件信息'},
        member:{title:'会员等级',type:'dict_key',dict_key:'member_level'},
        member_time:{title:'会员到期时间',type:'date'},
        phone: {title: '手机号'},
        create_time: {title: '绑定时间',type:'date',sortable:'custom',valueFormat:'timestamp'},
    },
    rules: {
        phone: [
            {validator: checkPhone, trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['bind','create_time','username','nickname','member','member_time','phone'],
        },
        bind_add:{
            show:true,
            title:'手动绑定',
            fields:['hardware_no','phone'],
        },
        search: {
            fields: ['title'],
        },
    }
}
