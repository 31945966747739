export default {
    fields: {
        free_id: {type: 'primary'},
        username: {title: '用户名'},
        info: {title: '说明',type:'textarea'},
        phone: {title: '电话', width: 150},
        month: {title: '赠送月数', placeholder: "赠送多少个月，就写几"},
        day: {title: '赠送天数', placeholder: "赠送多少天，就写几"},
        file:{title:'附件',type:'img_file',dir:'free_gis',accept:'.png,.jpg,.gif'},
        done_time: {title: '完成时间', type: 'daterange', sortable: 'custom', valueFormat: 'timestamp'},
        create_time: {title: '创建时间', type: 'daterange', sortable: 'custom', valueFormat: 'timestamp'},
    },
    rules: {
        phone: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ],
    },
    group: {
        show: {
            fields: ['phone', 'month','day', 'info', 'create_time', 'done_time','file'],
        },
        search: {
            fields: ['phone', 'create_time', 'done_time'],
        },
        add: {
            show:true,
            title:"添加赠送信息",
            fields: ['phone', 'month','day', 'info','file'],
        },
        edit: {
            show:true,
            title:"修改赠送信息",
            fields: ['phone', 'month','day', 'info','free_id','file'],
        },
    }
}
