export default {
    fields: {
        order_id: {type:'primary'},
        username: {title: '用户名'},
        phone: {title: '电话',width:150},
        platform: {title: '平台',width:100,type:'dict_key',dict_key:'platform_list'},
        pay_from: {title: '付款方式',type:'dict_key',dict_key:'pay_from',width:80},
        product_id: {title: '产品种类',width:120},
        price: {title: '原价',width:60},
        pay_price: {title: '付款金额',width:90},
        status: {title: '订单状态',width:90,dict_key:'bill_status',type:'dict_key'},
        is_payed: {title: '付款状态',width:100,dict_key:'pay_status',type:'dict_tag'},
        pay_time: {title: '付款时间',type:'daterange',valueFormat:'timestamp'},
        create_time: {title: '创建时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['phone','platform','pay_from','product_id','price','is_payed','create_time','pay_price','pay_time'],
        },
        export:{
            fields:['phone','platform','pay_from','price','is_payed','create_time','pay_price','pay_time'],
        },
        search: {
            fields: ['phone','platform','pay_from','is_payed','create_time','pay_time'],
        },
    }
}
