export default {
    fields: {
        id: {type:'primary'},
        title:{title:'标题'},
        sort:{title:'URL名称'},
        icon:{title:'图标'},
        pid:{type:'hidden'},
        status:{title:'是否启用',type:'dict_tag',dict_key: 'is_enabled'},
    },
    rules: {
        title: [
            {required: true, message: '请指定标题', trigger: 'blur'},
        ],
    },
    group: {
        show:{
            fields:['title','status'],
        },
        edit:{
            show:true,
            title:'编辑分类',
            fields:['id','title','status'],
        },
        add:{
            show:true,
            title:'添加分类',
            fields:['title','status','pid'],
        },
        search: {
            fields: ['title'],
        },
    }
}