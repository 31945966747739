export default {
    fields: {
        message_id:{type:'primary'},
        title: {title: '标题'},
        info: {title: '详情'},
        thumbnail: {title: '图片',type:'single_image',baseUrl:process.env.VUE_APP_MAIL_HOST},
        send:{title:'已发送'},
        current:{title:'即将发送'},
        create_time:{title:'创建时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
    },
    rules: {
        title: [
            {required: true, message: '标题不可以为空', trigger: 'blur'},
        ],
    },
    group: {
        show:{
            fields:['title','info','send','current','create_time'],
        },
        search: {
            fields: ['title','create_time'],
        },
        edit: {
            fields: ['message_id','title','info','thumbnail'],
            title: '修改群发信息',
            data: {},
            show: true,
        },
        add: {
            fields: ['title','info','thumbnail'],
            title: '新增群发信息',
            data: {

            },
            show: true,
        },
    }
}