export  default {
    fields: {
        menu_id:{type:'primary'},
        pid:{type:'hidden'},
        title: {title: '标题'},
        url:{title:'地址'},
        seo_title: {title: 'SEO标题'},
        target: {title: '是否新窗口打开', type: 'switch'},
        keywords: {title: 'Keywords',type:'textarea'},
        description: {title: 'Description',type:'textarea'},
        css_class: {title: '额外的CSS'},
        state: {title: '是否启用', type: 'switch'},
    },
    rules: {
        title: [
            {required: true, message: '标题不可以为空', trigger: 'blur'},
        ],
        // url: [
        //     {required: true, message: 'URL不可以为空', trigger: 'blur'},
        // ],
        // seo_title: [
        //     {required: true, message: 'SEO标题不可以为空', trigger: 'blur'},
        // ]
    },
    group: {
        add_menu: {
            fields: ['pid','title', 'url', 'seo_title', 'keywords','description','css_class','target' ,'state'],
            title: '添加导航',
            data: {type: 'menu', state: true},
            show: true,
        },
        edit_menu: {
            fields: ['menu_id','title', 'url', 'seo_title', 'keywords','description','css_class', 'target','state'],
            title: '编辑导航',
            show: true,
        },
        search: {
            fields: ['title', 'url'],
            title: '编辑菜单',
            show: true,
        },
    }
}