export default {
    fields: {
        id:{type:'primary'},
        title: {title: '标题'},
        times: {title: '展示次数',width:80},
        start_time: {title: '开始时间',type:'date_str'},
        end_time: {title: '结束时间',type:'date_str'},
        width: {title: '高度',width:80},
        height: {title: '宽度',width:80},
        enable:{title:'是否启用',type:'dict_tag',dict_key:'is_enabled',width:100},
    },
    rules: {

    },
    group: {
        show:{
            fields:['title','start_time','end_time','times','width','height','enable'],
        },
        edit:{
            fields:['id','times','enable'],
            show:true,
            title:'修改广告配置'
        },
        search: {
            fields: ['title'],
        }
    }
}