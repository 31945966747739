export default {
    fields: {
        username: {title: '用户名',width:240,type:'primary'},
        // phone: {title: '电话',width:150},
        // nickname: {title: '用户昵称'},
        // create_time: {title: '创建时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
        create_time: {title: '申请注销时间',type:'daterange',sortable:'custom',valueFormat:'timestamp'},
    },
    rules: {
        username: [
            {required: true, message: '请指定用户名', trigger: 'blur'},
        ]
    },
    group: {
        show:{
            fields:['username','create_time'],
        },
        search: {
            fields: ['username'],
        },
    }
}